export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        development: "http://data.pdtdi.id",
        production: "http://data.pdtdi.id", 
        // development: "http://127.0.0.1:8000" ,
        // production: "http://127.0.0.1:8000" 
    },
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__renataKidsUser",
      ACCESS_TOKEN: "__renataKidsValue",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm",
      DEF_FB : "__defFb", 
    },
    endPoint : {
      account : `/accounting/api/v1/account/`,
      accountOption : `/accounting/api/get-account-option/`,
      authv1 : `/auth/api/v1/`,
      bank : `/accounting/api/v1/bank/`,
      changePassword : `/auth/api/change-password`,
      contact : `/contact/api/v1/contact/`,
      jurnal : `/accounting/api/v1/jurnal/`,
      iuranAnggota : `keanggotaan/api/v1/iuran-anggota/`,
      importAnggotaSave : `/contact/api/v1/import-anggota/`,
      importAnggotaTry : `/contact/api/v1/try-import-anggota/`,
      defaultAccount : `/accounting/api/default-akun/`,
      masterIuran : `/res-app/api/v1/master-iuran/`,
      pesertaSeminar : `/seminar/api/v1/peserta-seminar/`,
      redundSeminar : `/seminar/api/v1/refund-seminar/`,
      seminar : `/seminar/api/v1/seminar/`,
      userv1 : `/auth/api/v1/user/`,
      pembayaranSeminar : `/seminar/api/v1/pembayaran-seminar/`,
      provinsi : `/res-app/api/v1/provinsi/`,
      jurnalManual : '/accounting/api/v1/jurnal-manual/',

      // pengeluaran
      pengeluaran : '/accounting/api/v1/pengeluaran/',
      pengeluaranPosting : '/accounting/api/v1/posting-pengeluaran/',
      pengeluaranUnPosting : '/accounting/api/v1/unposting-pengeluaran/',

      accountByGroup : '/accounting/api/v1/account-by-group/',
    },
    expiresSession : 86400,
    itemPerPage : 10,
    timeOutValue : 5000
};
  